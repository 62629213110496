import * as React from 'react';
import { alpha } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Video from '../images/videohome.mp4';

// Je veux mettre une vidéo en dessous du titre de la page d'accueil



export default function Home() {
  return (
    <Box
      id="Home"
      sx={(theme) => ({
        width: '100%',
        backgroundImage:
          theme.palette.mode === 'light'
            ? 'linear-gradient(180deg, #CEE5FD, #FFF)'
            : 'linear-gradient(#02294F, #090E10)',
        backgroundSize: '100% 20%',
        backgroundRepeat: 'no-repeat',
      })}
    >
      <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          pt: { xs: 14, sm: 20 },
          pb: { xs: 8, sm: 12 },
        }}
      >
        <Stack spacing={2} useFlexGap sx={{ width: { xs: '100%', sm: '70%' } }}>
          <Typography
            component="h1"
            variant="h1"
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', md: 'row' },
              alignSelf: 'center',
              textAlign: 'center',
            }}
            //! Titre de la page
          >
            Clean&nbsp; 
            <Typography
              component="span"
              variant="h1"
              sx={{
                color: (theme) =>
                  theme.palette.mode === 'light' ? 'primary.main' : 'primary.light',
              }}
            >
              the city
            </Typography>
          </Typography>
          <Typography variant="body1" textAlign="center" color="text.secondary" >
           Pour retrouver la beauté de ce quartier, vous allez devoir effectuer votre métier d’éboueur <br />
           et nettoyer le quartier de fond en comble pour enlever tous ces déchets qui traînent.
        
          </Typography>
          <Stack //! description brève du jeu
            direction={{ xs: 'column', sm: 'row' }}
            alignSelf="center"
            spacing={1}
            useFlexGap
            sx={{ pt: 2, width: { xs: '100%', sm: 'auto' } }}
          >
            <Button variant="contained" color="primary" href='https://mmi.unilim.fr/~boutin20/SAE402/'>
              Play Now
            </Button>
          </Stack>
        </Stack>
        <Box
          component="video"
          autoPlay
          loop
          muted
          playsInline
          src={Video}
          sx={{
            width: '100%',
            height: 'auto',
            mt: 4,
            borderRadius: 2,
            boxShadow: 8,
          }}
        />
      </Container>
    </Box>
  );
}
