import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import AutoFixHighRoundedIcon from '@mui/icons-material/AutoFixHighRounded';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import QueryStatsRoundedIcon from '@mui/icons-material/QueryStatsRounded';
import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined';
import SupportAgentRoundedIcon from '@mui/icons-material/SupportAgentRounded';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import MemoryOutlinedIcon from '@mui/icons-material/MemoryOutlined';
import RemoveRoadOutlinedIcon from '@mui/icons-material/RemoveRoadOutlined';
import { color } from '@mui/system';

const items = [
  {
    icon: <QueryStatsRoundedIcon />,
    title: 'Production totale de déchets',
    description:
      'En 2020, la France a produit 310 millions de tonnes de déchets, soit une diminution de 9,7 % par rapport à 2018.',
  },
  {
    icon: <LanguageOutlinedIcon />,
    title: 'Comparaison internationale',
    description:
      'La France se situe légèrement en deçà de la moyenne européenne en termes de tonnes de déchets par habitant (5,1 t / hab.).',
  },
  {
    icon: <DeleteOutlineOutlinedIcon />,
    title: 'Déchets ménagers',
    description:
      'La quantité de déchets ménagers s’élève à 29,7 millions de tonnes, soit seulement 8,7 % des déchets totaux produits en France.',
  },
  {
    icon: <WarningAmberOutlinedIcon/>,
    title: 'Déchets plastiques',
    description:
      'En France, 13,2 millions de tonnes de déchets plastiques sont produits chaque année.',
  },
  {
    icon: <MemoryOutlinedIcon />,
    title: 'Déchets électroniques',
    description:
      'En France, environ 2 millions de tonnes de déchets électroniques sont générées chaque année.',
  },
  {
    icon: <RemoveRoadOutlinedIcon />,
    title: 'Sur les routes',
    description:
      "76 970 tonnes de déchets ont été abandonnés en 2020 sur l'ensemble des routes de France métropolitaine, soit 193 kg/km et 1,18 kg/hab. (pour environ 400 000 km de routes).",
  },
];

export default function Stats() {
  return (
    <Box
      id="Stats"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        color: 'white',
        bgcolor: 'primary',
      }}
    >
      <Container
        sx={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: { xs: 3, sm: 6 },
        }}
      >
        <Box
          sx={{
            width: { sm: '100%', md: '60%' },
            textAlign: { sm: 'left', md: 'center' },
          }}
        >
          <Typography component="h2" variant="h4" color="text.primary">
          Pourquoi choisir le tri sélectif ?
          </Typography>
          <Typography variant="body1" color="text.secondary">
          Nous avons choisi de traiter cet éco-geste, car c'est selon nous un des plus importants et qui nous concerne tous. C'est pourquoi vous êtes un éboueur devant réparé ces incivilités dans "Clean The City".
          </Typography>
        </Box>
        <Grid container spacing={2.5}>
          {items.map((item, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Stack
                direction="column"
                color="inherit"
                component={Card}
                spacing={1}
                useFlexGap
                sx={{
                  p: 3,
                  height: '100%',
                  border: '1px solid',
                  borderColor: 'border.light',
                  background: 'transparent',
                  backgroundColor: 'primary',
                }}
              >
                <Box sx={{color: 'icon.light'}}>{item.icon}</Box>
                <div>
                  <Typography fontWeight="medium" gutterBottom color="text.primary">
                    {item.title}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {item.description}
                  </Typography>
                </div>
              </Stack>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
}
